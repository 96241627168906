<template>
  <section>
    <v-progress-linear :active="loading" :indeterminate="loading" absolute top></v-progress-linear>
    <v-row justify="space-around">
      <v-card class="flip-card" width="75%">
        <v-card-title class="title blue-grey lighten-4">
          {{site.name}}
          <v-icon right color="white">business</v-icon>
        </v-card-title>

        <v-divider />

          <v-scroll-y-transition>
            <v-list class="flip-card-inner" dense>
              <v-list-item>
                <v-list-item-content><b>Address:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ site.streetNumber }} {{site.street}} 
                  {{site.city}}, {{site.state}} {{site.postalCode}}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Latitude:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ site.latitude }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Longitude:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ site.longitude }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Timezone:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ selectedTimezone }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Weather Station:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ selectedWeatherStation }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Utility Companies:</b></v-list-item-content>
              </v-list-item>
              <v-list-item v-for="utilityCompany in selectedUtilityCompanies" :key="utilityCompany.id" class="portfolio-utility-co">
                <v-list-item-title class="align-end" style="padding-left: 15px">        
                  {{ utilityCompany.name }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Market Context:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ selectedMarketContext }}
                </v-list-item-content>
              </v-list-item>

                <v-list-item>
                <v-list-item-content><b>Cost per kWh ($):</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ site.kwhCost }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Cost per Therm ($):</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ site.thermCost }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Site Type:</b></v-list-item-content>
                <v-list-item-content class="align-end">        
                  {{ selectedSiteType }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Square Footage:</b></v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ site.squareFootage.toLocaleString() }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Floors:</b></v-list-item-content>
                <v-list-item-content class="align-end">{{ site.floors }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Occupants:</b></v-list-item-content>
                <v-list-item-content class="align-end">{{ site.occupants }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Year Built:</b></v-list-item-content>
                <v-list-item-content class="align-end">{{ site.year }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content><b>Hours of Operation:</b></v-list-item-content>
              </v-list-item>
              <section class="portfolio-hours-of-op">
                <v-list-item v-for="time in siteStartStopTimes" :key="time.id" >
                  <v-list-item-title>{{ time.dayOfWeekName }}</v-list-item-title>
                  <v-list-item-title class="align-end">{{ time.startStopTime.siteStartTime }} - {{ time.startStopTime.siteEndTime }}</v-list-item-title>
                </v-list-item>
              </section>

            </v-list>
          </v-scroll-y-transition>

        <v-card-actions>

        </v-card-actions>
      </v-card>
    </v-row>
  </section>
</template>

<style>
  .search-location {
    display: block;
    width: 80vw;
    outline: none;
    /* height: 30px;
    line-height: 30px; */
    text-align: left;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.42);
    border-width: 0 0 thin 0;
  }

  .portfolio-utility-co .v-list-item {
    min-height: 30px;
  }

  .portfolio-hours-of-op {
    overflow-y: scroll;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .portfolio-hours-of-op .v-list-item {
    min-height: 30px;
  }
</style>
<script>
import { mask } from "vue-the-mask";
import api from "../../site/_api";

import { updateSiteResourceModel } from "../../site/_resourceModels/updateSiteResourceModel";
import { createSiteStartStopTimeResourceModel } from "@/modules/site/_resourceModels/createSiteStartStopTimeResourceModel";
import { createStartStopTimeResourceModel } from "@/modules/site/_resourceModels/createStartStopTimeResourceModel";

export default {
  components: {
  },

  directives: {
    mask
  },

  data() {
    return {
      loading: true,
      readOnly: true,
      phoneMask: "###-###-####",
      emailMethod: false,

      states: [],

      weatherStations: [],
      selectedWeatherStation: null,
      timezones: [],
      selectedTimezone: null,
      utilityCompanies: [],
      selectedUtilityCompanies: null,
      marketContexts: [],
      selectedMarketContext: null,
      siteTypes: [],
      selectedSiteType: null,

      contactTypes: [
        { value: 1, text: "Text" },
        { value: 2, text: "Email" },
        { value: 3, text: "Phone" }
      ],
      
      dayOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      years: this.getYears(),
      pickerLabels: { start: "Open Time", end: "Closing Time" },
      google: null,
      autocompleted: null,
      site: new updateSiteResourceModel(),
      siteName: null,
      siteStartStopTimes: null,
    };
  },

  mounted() {},

  async created() {
    await this.getSite();
    await this.getWeatherstations().then(() => this.setWeatherStation());
    await this.getTimezones().then(() => this.setTimezone());
    await this.getUtilityCompanies().then(() => this.setUtilityCompany());
    await this.getMarketContexts().then(() => this.setMarketContext());
    await this.getSiteTypes().then(() => this.setSiteType());
    await this.getStates();
    await this.getSites();
  },

  methods: {
    getStates() {
      this.states = api.getStates();
    },

    async getSites() {
      try {
        let response = await api.getSites();
        this.sites = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    async getWeatherstations() {
      try {
        let response = await api.getWeatherStations();
        this.weatherStations = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    setWeatherStation() {
      let weatherStation = this.weatherStations.filter((w) => {
          return w.id === this.site.weatherStationId;
      });
      this.selectedWeatherStation = weatherStation[0]?.name;
    },

    async getTimezones() {
      try {
        let response = await api.getTimezones();
        this.timezones = response;


      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    setTimezone() {
      let timezone = this.timezones.filter((tz) => {
          return tz.id === this.site.timeZoneId;
      });
      this.selectedTimezone = timezone[0]?.name;
    },

    async getUtilityCompanies() {
      try {
        let response = await api.getUtilityCompanies();
        this.utilityCompanies = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    setUtilityCompany() {
      this.selectedUtilityCompanies = this.utilityCompanies.filter((u) => {
        return this.site.utilityCompanyIds.includes(u.id);
      });
    },

    async getMarketContexts() {
      try {
        let response = await api.getMarketContexts();
        this.marketContexts = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    setMarketContext() {
      let marketContext = this.marketContexts.filter((m) => {
        return m.id === this.site.marketContextId;
      });
      this.selectedMarketContext = marketContext[0]?.name;
    },

    async getSiteTypes() {
      try {
        let response = await api.getSiteTypes();
        this.siteTypes = response;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
    },

    setSiteType() {
      let siteType = this.siteTypes.filter((t) => {
        return t.id === this.site.siteTypeId;
      })
      this.selectedSiteType = siteType[0]?.name;
    },

    async getSite() {
      try {
        let response = await api.getSiteById(this.$route.params.id);
        this.site = response;
        this.siteName = response.name;
        this.siteStartStopTimes = this.mapSiteStartStopTime(
          this.site.siteStartStopTime
        );
      } catch (error) {
        this.server_errors = this.errorSummary(error);
      }
      this.loading = false;
    },

    getYears() {
      let thisYear = new Date().getFullYear(),
        yearList = [];
      let startYear = 1900;
      while (startYear <= thisYear) {
        yearList.push(thisYear--);
      }
      return yearList;
    },

    errorSummary(error) {
      return error.response
        ? error.response.data.Errors
          ? error.response.data.Errors
          : error.response.data.errors
          ? error.response.data.errors
          : [error]
        : [error];
    },

    timezoneFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    mapSiteStartStopTime(sssTimes) {
      let mappedTimes = [];

      for (let i = 0; i < this.dayOfWeek.length; i++) {
        let existingSssTime = sssTimes.find((t) => t.dayOfWeekId === i);
        if (existingSssTime) {
          existingSssTime.isOpen = true;
          mappedTimes.push(existingSssTime);
        } else {
          let newSssTime = this.newStartStopTime(i, this.dayOfWeek[i], false);
          mappedTimes.push(newSssTime);
        }
      }

      return mappedTimes;
    },

    newStartStopTime(
      index,
      dayOfWeekName,
      isOpen,
      startStopTime = new createStartStopTimeResourceModel(
        "07:00:00",
        "19:00:00"
      )
    ) {
      let newSssTime = new createSiteStartStopTimeResourceModel(
        this.$route.params.id,
        index,
        dayOfWeekName,
        isOpen
      );
      // let startStopTime = new createStartStopTimeResourceModel(
      //   "07:00:00",
      //   "19:00:00"
      // );
      startStopTime.id = 0; //TODO: make an update resource model
      newSssTime.startStopTime = startStopTime;
      newSssTime.id = 0;
      return newSssTime;
    },
  }
};
</script>