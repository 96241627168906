import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=76d2688f&"
import script from "./Details.vue?vue&type=script&lang=js&"
export * from "./Details.vue?vue&type=script&lang=js&"
import style0 from "./Details.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCardActions,VCardTitle,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemTitle,VProgressLinear,VRow,VScrollYTransition})
